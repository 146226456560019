/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React, {useState, useEffect} from 'react';
import Axios from 'axios'
import { Link , useHistory} from 'react-router-dom';
import JsCookie from 'js-cookie'; 
import { api_base_url, get_config, cookieDomainExtension, image_base_url } from 'constants/Constants'

export default function UserTokens()
{
    const history = useHistory()
    const [tokens_list, set_tokens_list] = useState([])
    const [user_token] = useState(JsCookie.get('token',{domain:cookieDomainExtension}))
    
    useEffect(()=>{
        get_all_tokens_list()
    },[history])

    const get_all_tokens_list=()=>
    { 
        console.log(get_config(user_token))
        Axios.get(`${api_base_url}tokens/tokens_list`, get_config(user_token)).then(res => 
        {
            if(res.data.status)
            {
                console.log(res.data.message)
                set_tokens_list(res.data.message)
            }
        })
    }

    const Enabletoken=(e)=>{
        
        Axios.get(`${api_base_url}tokens/enable_token/`+e.id, get_config(user_token)).then(res => 
        {  
            if(res.data.status)
            {
                get_all_tokens_list()
            }
        })
    }

    const Disabletoken=(e)=>
    {
        Axios.get(`${api_base_url}tokens/disable_token/`+e.id, get_config(user_token)).then(res => 
            {  
                if(res.data.status)
                {
                    get_all_tokens_list()
                }
            })
    }


    return(
        <div id="Content" className="manage_token">
        <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h4 className="mt-3 market_title">Manage Tokens</h4>
                        <p>Here you can manage your tokens</p>
                    </div>
                    <div className="col-md-4">
                        <Link className="list_coin_button" to="/manage_tokens/create_new">Create New</Link>
                    </div>
                </div>
            <div className="user_tokens">
                <div className="market_exchange ">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                <th>Coin name</th>
                                <th>Coin symbol</th>
                                <th>Email ID</th>
                                <th>Contract address</th>
                                <th>Approval status</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {
                                    tokens_list.length > 0
                                    ?
                                        tokens_list.map((e: any) => {
                                            return <tr>
                                            <td>
                                            {/* "http://192.168.1.100:81/aro_live/uploads/tokens/1651929811627672d37f88e.png" */}
                                            <img src={`${(e.coin_image ? image_base_url +e.coin_image : image_base_url +"default.png")}`} alt="ANDX" /> 
                                                {e.coin_name}
                                                </td>
                                            <td>{e.coin_symbol}</td>
                                            <td>{e.email_id?e.email_id:"--"}</td>
                                            <td>{e.contract_address?e.contract_address:"--"}</td>
                                            <td>{
                                                    e.approval_status === "0"
                                                    ?
                                                    <span className="text-warning">Pending</span>
                                                    :
                                                    <span className="text-success">Approved</span>
                                                }<br/>{
                                                    e.coin_status === "0"
                                                    ?
                                                    <span className="text-warning">Disabled</span>
                                                    :
                                                    <span className="text-success">Enabled</span>
                                                }</td>
                                            <td className="nowrap-td">
                                                {
                                                    e.coin_status === "1"
                                                    ?
                                                    <span className="action_btn" onClick={()=>Disabletoken(e)}><i className="fas fa-eye-slash" />Disable</span>
                                                    :
                                                    <span className="action_btn" onClick={()=>Enabletoken(e)}><i className="fas fa-eye" />Enable</span> 
                                                }
                                                {
                                                    e.approval_status==="1" && e.coin_status === "1"
                                                    ?
                                                    <span className="action_btn"><Link to={"/market/"+e.coin_symbol}><i className="fas fa-edit" />View</Link></span>
                                                    :
                                                    null
                                                    
                                                }
                                                {/* <span className="action_btn"><Link to={"/manage_tokens/edit_token/"+e.id}><i className="fas fa-edit" />Edit</Link></span>  */}
                                            </td>
                                        </tr>
                                        }
                                    )
                                    :
                                    <td colSpan={6}>No Tokens Found!</td>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}