/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-template */
/* eslint-disable no-console */
import React from 'react';
import { withRouter } from 'react-router-dom'; 
import queryString from 'query-string';

class Register extends React.Component<any, any> { 
    constructor(props) {
        super(props);
        this.state = {
          url_param:queryString.parse(window.location.search)
        }
    }
    
    componentDidMount()
    {    
        // console.log("https://app.arrano.network/register?ref="+this.state.url_param.ref);
        // console.log(this.state.url_param.ref)
        window.location.replace("https://app.arrano.network/register?ref="+this.state.url_param.ref)
    }
    
    render(){
        return(
                <></>
        )
    } 
}

export default withRouter(Register);