import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import Cookies from 'js-cookie';

import {auth_website_url, cookieDomainExtension} from  '../../constants/Constants';

export default function Index(props:any)
{
    // const Cmp = props.cmp
    const router = useHistory()
    const userToken = Cookies.get('token',{domain:cookieDomainExtension})
    const user_email_status =  parseInt(Cookies.get('local_email_verify_status',{domain:cookieDomainExtension}))
   
    return <div> 
            {
              userToken ?
                  user_email_status === 1 ?
                  <props.cmp /> 
                  :
                 <>
                  {
                    window.location.replace(`${auth_website_url}auth/register-verify-email`)
                  }
                 </>
                : 
                <>
                {
                  window.location.replace(`${auth_website_url}auth/login`)
                }
                </>
             }
           </div>
}