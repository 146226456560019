/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useState, useEffect,useCallback, useRef } from 'react';  
import MetaTags from 'react-meta-tags' 
import { Link , useHistory} from 'react-router-dom'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Axios from 'axios'
import JsCookie from 'js-cookie'; 
import { api_base_url, get_config, cookieDomainExtension } from 'constants/Constants'


export default function List_your_coin()
{
  const router = useHistory()
  const post_slug = router.location.pathname.substring(1)
  const post_slug2 = post_slug.substring(post_slug.lastIndexOf("/"))
  const post_slug3 = post_slug2.replace(/\//,"")
  console.log(post_slug3)

  const [request_row_id, set_request_row_id] = useState(post_slug3 === 'create_new' ? post_slug3:"")
  const [coin_id, set_coin_id] = useState("")
  const [email_id, set_email_id] = useState("")
  const [coin_symbol, set_coin_symbol] = useState("")
  const [coin_name, set_coin_name] = useState("")
  const [official_forum_url, set_official_forum_url] = useState("")
  const [website_link, set_website_link] = useState("")
  const [twitter_username, set_twitter_username] = useState("")
  const [facebook_username, set_facebook_username] = useState("")
  const [coin_description, set_coin_description] = useState("")
  const [contract_address, set_contract_address] = useState("")
  const [genesis_date, set_genesis_date] = useState("")
  const [coin_image, set_coin_image] = useState("")
  const [valid_email_id, set_valid_email_id] = useState<any>()
  const [valid_coin_symbol, set_valid_coin_symbol] = useState<any>()
  const [valid_coin_name, set_valid_coin_name] = useState<any>() 
  const [valid_coin_description, set_valid_coin_description] = useState<any>()
  const [valid_contract_address, set_valid_contract_address] = useState<any>() 
  const [valid_coin_image, set_valid_coin_image] = useState<any>() 
  const [valid_form, set_valid_form] = useState<any>() 
  const [valid_coin_id, set_valid_coin_id] = useState<any>()
  
  const [upImg, setUpImg] = useState<any>()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 })
  const [completedCrop, setCompletedCrop] = useState(null)
  const [upload_image_message, set_upload_image_message] = useState("") 
  const [blobFile, set_blobFile] = useState<any>()  
  const [profile_img, set_profile_img] = useState("")  

  const [modal, set_modal] = useState(false) 
  const [image_modal_show, set_image_modal_show] = useState(false) 
  const [alert_message, set_alert_message] = useState({status:false, title:"", message:""}) 
  const [user_token] = useState(JsCookie.get('token',{domain:cookieDomainExtension}))

  // useEffect(() => 
  // { 
  //   // if(localStorage.getItem("token_address"))
  //   // { 
  //   //   router.push({pathname : "/"}) 
  //   // }
  // }, [])

  const SubmitDetails=()=>
  {  
    let formIsValid = true
    set_valid_coin_id("")
    set_valid_email_id("")
    set_valid_coin_symbol("")
    set_valid_coin_name("")
    set_valid_coin_description("")
    set_valid_contract_address("")
    set_valid_coin_image("") 
    set_upload_image_message("")
    set_alert_message({status:false, title:"", message:""}) 
    const reg_email = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i
    const reg_coin_description= /^[a-zA-z0-9]{4,25}$/i
    const reg_contract_address= /^[a-zA-z0-9]{20,50}$/i
    const reg_coin_symbol= /^[a-zA-z_]{2,25}$/i
    const reg_coin_name= /^[a-zA-z_ ]{2,25}$/i 
    const reg_required= /^[a-zA-z0-9_-]{1,100}$/

      if(email_id){

        if(email_id.length < 0)
      {
        set_valid_email_id("Email ID feild is required")  
        formIsValid = false
      }
      else if(!reg_email.test(email_id))
      {
        set_valid_email_id("Enter valid Email ID") 
        formIsValid = false
      }
      }
      

      if(!reg_required.test(coin_symbol))
      {
        set_valid_coin_symbol("Coin symbol feild is required")
        formIsValid = false
      }
      else if(!reg_coin_symbol.test(coin_symbol))
      {
        set_valid_coin_symbol("The coin symbol must be at least 2 characters") 
        formIsValid = false
      }

      if(!reg_required.test(coin_name))
      {
        set_valid_coin_name("Coin name feild is required")
        formIsValid = false
      }
      else if(!reg_coin_name.test(coin_name))
      {
        set_valid_coin_name("The coin name must be at least 3 characters")
        formIsValid = false
      }
      if(!reg_required.test(coin_id))
      {
        set_valid_coin_id("Coin Id feild is required")
        formIsValid = false
      }
      if(contract_address){
            if(!reg_required.test(contract_address))
          {
            set_valid_contract_address("Contract address feild is required")
            formIsValid = false
          }
          else if(!reg_contract_address.test(contract_address))
          { 
            set_valid_contract_address("The contact address must be at least 20 characters")
            formIsValid = false
          }

      }
      

      if(coin_description.length <= 0)
      {
        set_valid_coin_description("The coin description field is required")
        formIsValid = false
      } 
      else if(coin_description.length <4)
      {
        set_valid_coin_description("The coin description must be at least 4 characters")
        formIsValid = false
      } 

      if(upImg == null)
      {
        set_upload_image_message("Please select the image before upload")
        formIsValid = false
      }

      if(!formIsValid) { return }

      const reqData = {
        "coin_id" : coin_id,
        "email_id" : email_id,
        "coin_symbol" : coin_symbol,
        "coin_name" : coin_name,
        "official_forum_url" : official_forum_url,
        "website_link" : website_link,
        "twitter_username" : twitter_username,
        "facebook_username" : facebook_username,
        "coin_description" : coin_description,
        "contract_address" : contract_address,
        "genesis_date" : genesis_date,
        "coin_image" : coin_image
      }
      
      Axios.post(`${api_base_url}tokens/create_new`, reqData, get_config(user_token)).then(res => 
      {
        if(res.data.status)
        {
          set_alert_message({status:true, title:"Thank You", message:res.data.message.alert_message})
          set_email_id("")
          set_coin_id("")
          set_coin_symbol("")
          set_coin_name("")
          set_coin_id("")
          set_official_forum_url("")
          set_website_link("")
          set_twitter_username("")
          set_facebook_username("")
          set_coin_description("")
          set_contract_address("")
          set_genesis_date("")
          set_valid_coin_description("")
          setUpImg("")
          set_coin_image("")
        }
        else
        {
            set_alert_message({status:true, title:"Oops!", message:res.data.message.alert_message})
            if(res.data.message.coin_id)
            { 
              set_valid_coin_id(res.data.message.coin_id)
            } 
            
            if(res.data.message.email_id)
            {
              set_valid_email_id(res.data.message.email_id)
            } 
            
            if(res.data.message.coin_symbol)
            {
              set_valid_coin_symbol(res.data.message.coin_symbol)
            } 
            
            if(res.data.message.coin_name)
            {
              set_valid_coin_name(res.data.message.coin_name)
            }  
            
            if(res.data.message.coin_description)
            {
              set_valid_coin_description(res.data.message.coin_description)
            } 
            
            if(res.data.message.contract_address)
            {
              set_valid_contract_address(res.data.message.contract_address)
            } 
            
            if(res.data.message.coin_image)
            {
              set_valid_coin_image(res.data.message.coin_image)
            }   
        }
      })
  }

  
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(e.target.files[0])
      set_image_modal_show(true)
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  const onCropComplete = (crops) => 
  {
    setCompletedCrop(crops) 
  
    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = crops 

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) { 
          return
        }
        blob.name = 'newFile.jpeg'
        const fileUrl = window.URL.createObjectURL(blob)
        window.URL.revokeObjectURL(fileUrl)
         // Set blob image inside the state here 

        const reader = new FileReader()
        // reader.addEventListener('load', () => setUpImg(reader.result))
        reader.readAsDataURL(blob);
        reader.onloadend = function () 
        {
           console.log(reader.result)
           set_blobFile(reader.result)
        }

        resolve(fileUrl)
      }, 'image/jpeg')
    }) 
  } 

  const setTokenImage = () =>
  { 
    set_coin_image(blobFile)
    set_image_modal_show(false)
   // document.getElementById("imageUploadForm").reset()
  }
  
  return (
      <> 
     
      <div id="Content" className="">
      <MetaTags>
        <title>Markets - Decentralized Exchange | DEX </title>
        <link rel="canonical" href="https://arrano.network/" />
        <meta name="description" content=" Arrano Network is a powerful and user-friendly decentralized exchange developed by the Arrano Developers community. Arrano DEX..." />
        <meta name="keyword" content="Arrano Network, Arrano DEX, ANO Token, Arrano Tokens, Arrano DeFi, Arrano airdrop , ANO Defi token , Arrano Network Community , Arrano Launchpad, Arrano Exchange Arrano Defi Project , ANO governance token" />
        <meta property="og:title" content="Markets - Decentralized Exchange | DEX" />
        <meta property="og:image" content="/bsc_logo.png" />
      </MetaTags> 
          <div className="container">
            <div className="market_header_main">
              <h4 className="market_title">List a Coin</h4>
              <p>Submit your coin details for listing on Arrano markets list. </p>
              {/* <p><Link className="list_coin_button" to="/manage_tokens">Go Back</Link></p> */}
            </div>
            <div className="main_block_list_coin">
              <div className="col-md-12"> 
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Coin ID</label>
                      <input type="text" value={coin_id} placeholder="Coin ID"  className="form-control" id="coin_id" onChange={(e)=> set_coin_id(e.target.value)}   /> 
                      <div className="error">{valid_coin_id}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email ID</label>
                      <input type="email" placeholder="Enter Email ID" value={email_id} onChange={(e)=> set_email_id(e.target.value)} className="form-control" id="email_id" />
                      {valid_email_id && <div className="error">{valid_email_id}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Contract Address</label>
                      <input type="text" placeholder="Enter Contract Address" value={contract_address} onChange={(e)=> set_contract_address(e.target.value)} className="form-control"  />
                      {valid_contract_address && <div className="error">{valid_contract_address}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Coin Name</label>
                      <input type="text" placeholder="Enter Coin Name" value={coin_name} onChange={(e)=> set_coin_name(e.target.value)} className="form-control" id="pwd" />
                      {valid_coin_name && <div className="error">{valid_coin_name}</div>}
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Symbol</label>
                      <input type="text" placeholder="Enter Symbol" value={coin_symbol} onChange={(e)=> set_coin_symbol(e.target.value)} className="form-control" id="pwd" />
                      {valid_coin_symbol && <div className="error">{valid_coin_symbol}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Official forum url</label>
                      <input type="text" placeholder="Enter Official forum url" value={official_forum_url} onChange={(e)=> set_official_forum_url(e.target.value)} className="form-control"  />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Website Link</label>
                      <input type="text" placeholder="Enter Website Link" value={website_link} onChange={(e)=> set_website_link(e.target.value)} className="form-control" id="pwd" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Twitter Username</label>
                      <input type="text" placeholder="Enter Twitter Username" value={twitter_username} onChange={(e)=> set_twitter_username(e.target.value)} className="form-control" id="pwd" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Facebook Username</label>
                      <input type="text" placeholder="Enter Facebook Username" value={facebook_username} onChange={(e)=> set_facebook_username(e.target.value)} className="form-control" id="pwd" />
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Coin Description</label>
                      <input type="text" placeholder="Enter Coin description" value={coin_description} onChange={(e)=> set_coin_description(e.target.value)} className="form-control" id="pwd" />
                      {valid_coin_description && <div className="error">{valid_coin_description}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Genesis Date</label>
                      <input type="date" placeholder="Enter Genesis Date" value={genesis_date} onChange={(e)=> set_genesis_date(e.target.value)} className="form-control" id="pwd" />
                    </div>
                  </div>
                  
                  
                  <div className="col-md-6">
                  <form id="imageUploadForm">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Coin Logo</label>
                          <input type="file" onChange={onSelectFile} className="form-control"  />
                        </div> 
                      </div>

                      <div className="col-md-3">
                      
                        {
                          coin_image ?
                          <>
                            <label>Coin Logo</label><br/>
                            <img alt="CoinImage" src={`${coin_image}`}  style={{width:"100%"}} />
                          </>
                          :
                          ""
                        }

                        <div style={{display:"none"}}>
                            {
                              upImg
                              ?
                              <canvas
                              ref={previewCanvasRef}
                              style={{
                                  width: Math.round(completedCrop?.width ?? 0),
                                  height: Math.round(completedCrop?.height ?? 0)
                              }}
                              />
                              :
                              null
                            } 
                        </div>
                      </div>

                      
                    </div>
                    
                      

                    {upload_image_message && <div className="error">{upload_image_message}</div>}
                    </form>
                  </div>
                  
                </div>
                <button type="button"  onClick={()=>SubmitDetails()} className="btn list_coin_btn">Submit</button>
                {/* {valid_form && <label>{valid_form}</label>} */}
              </div>
            </div>
          </div>
        </div>
        {
          modal ?
          <div className="modal" id='myModal'>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Wallet</h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                Please Connect your wallet
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=>set_modal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
          :
          null
        }
       
<div className={`modal fade ${(alert_message.status ? " show image_modal_show":"")}`}>
  <div className="modal-dialog modal-sm">
    <div className="modal-content">
     
      <div className="modal-body">
      <button type="button" className="close" data-dismiss="modal" onClick={()=>set_alert_message({status:false, title:"", message:""})}>&times;</button>
        <h3>{alert_message.title}</h3>
        <p>{alert_message.message}</p>
      </div>
      
    </div>
  </div>
</div>


<div className={`modal fade ${(image_modal_show ? " show image_modal_show":"")}`} id="myModal">
  <div className="modal-dialog">
    <div className="modal-content">
      {/* Modal Header */}
      <div className="modal-header">
        <h4 className="modal-title">Set Token Image</h4>
        <button type="button" className="close" data-dismiss="modal"  onClick={()=>set_image_modal_show(false)}>×</button>
      </div>
      {/* Modal body */}
      <div className="modal-body text-center">
        <div>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c: any) => setCrop(c)}
            onComplete={(c: any) => onCropComplete(c)}
          />
        </div>
     

        <button type="button" onClick={()=>setTokenImage()} className="btn btn-success">Set Image</button>
      </div>
    </div>
  </div>
</div>

        </>
    ) 
} 
