// Live Domain
 export const cookieDomainExtension = 'arrano.network'
 export const website_base_url = 'https://arrano.network/' 
 export const auth_website_url = 'https://app.arrano.network/'

 // Live API URL
//  export const api_base_url =  'https://api.brandsneed.com/aro/' 
//  export const image_base_url = 'https://api.brandsneed.com/aro/uploads/tokens/'
 
 
 // Live API URL
 export const api_base_url =  'https://auth.arrano.network/aro/' 
 export const image_base_url = 'https://auth.arrano.network/aro/uploads/tokens/'
 
 
// Local Domain
// export const cookieDomainExtension = '192.168.1.100'
// export const website_base_url = 'http://192.168.1.100:3021/'
// export const auth_website_url = 'http://192.168.1.100:3020/'

// API URL
// export const api_base_url = 'http://192.168.1.100:81/aro_live/'
// export const image_base_url = 'http://192.168.1.100:81/aro_live/uploads/tokens/'


export const X_API_KEY = 'RQZE0BCC3O4R8XR4WKG8NEYV8LHYUC0KSNHE5QASLDHYYN910B7LKLVOHLX4'

export const get_config=(user_token)=>
{
    if(user_token)
    {
        return { 
            headers: {
              "X-API-KEY":X_API_KEY,
              token:user_token
            }
          }
    }
    return { 
    headers: {
        "X-API-KEY":X_API_KEY
    }
    }
}




export const receiver_eth_address = '0xDd230116c0ED90B5f11B529edBfEC5390E4462f8'
export const receiver_trx_address = 'TTyjvojG9pY34qJS4W1mD5tjab2fGAdgJh'
export const metaMaskNetworkAccpeted = 'main' 
export const nodejs_api_base_url = 'https://aro-app.herokuapp.com/'
export const bep_contract_address = '0xb63a911ae7dc40510e7bb552b7fcb94c198bbe2d'
export const contract_address = '0xeE08F393F4Cc9eA0508246fFc0D693944deE6036'
export const trx_contract_addres =  'TCdvet6k6PdtiNd2YDVPAexDP11T7PZowc'
export const givenProvider = "wss://arrano.network"
export const zero_ex_api_url = 'https://api.0x.org/'

export const exchanges_id = ["binance-usd", "bitcoin", "ethereum-classic", "dogecoin", "ethereum", "binancecoin", "eos", "ripple", "cardano", "litecoin", "bittorrent-2", "bitcoin-cash", "tron", "vechain", "polkadot", "usd-coin", "qtum", "ontology", "omisego", "neo", "chainlink", "stellar", "filecoin", "wink", "terra-luna", "theta-token", "chiliz", "tezos", "swipe", "dash", "uniswap", "avalanche-2", "lisk", "matic-network", "pancakeswap-token", "monero", "zcash", "solana", "iostoken", "cosmos", "iota", "sushi", "holotoken", "nem", "true-usd", "algorand", "zilliqa", "the-graph", "1inch", "curve-dao-token", "ftx-token"]

export const exchange_list = [
    {id: "bitcoin", symbol: "btc", image: "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579"}
    ,{id: "binance-usd", symbol: "busd", image: "https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766"}
    ,{id: "dogecoin", symbol: "doge", image: "https://assets.coingecko.com/coins/images/5/small/dogecoin.png?1547792256"}
    ,{id: "ethereum-classic", symbol: "etc", image: "https://assets.coingecko.com/coins/images/453/small/ethereum-classic-logo.png?1547034169"}
    ,{id: "ethereum", symbol: "eth", image: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880"}
    ,{id: "binancecoin", symbol: "bnb", image: "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615"}
    ,{id: "ripple", symbol: "xrp", image: "https://assets.coingecko.com/coins/images/44/small/xrp-symbol-white-128.png?1605778731"}
    ,{id: "cardano", symbol: "ada", image: "https://assets.coingecko.com/coins/images/975/small/cardano.png?1547034860"}
    ,{id: "tron", symbol: "trx", image: "https://assets.coingecko.com/coins/images/1094/small/tron-logo.png?1547035066"}
    ,{id: "vechain", symbol: "vet", image: "https://assets.coingecko.com/coins/images/1167/small/VeChain-Logo-768x725.png?1547035194"}
    , {id: "litecoin", symbol: "ltc", image: "https://assets.coingecko.com/coins/images/2/small/litecoin.png?1547033580"}
    , {id: "polkadot", symbol: "dot", image: "https://assets.coingecko.com/coins/images/12171/small/aJGBjJFU_400x400.jpg?1597804776"}
    , {id: "usd-coin", symbol: "usdc", image: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389"}
    , {id: "qtum", symbol: "qtum", image: "https://assets.coingecko.com/coins/images/684/small/qtum.png?1547034438"}
    , {id: "ontology", symbol: "ont", image: "https://assets.coingecko.com/coins/images/3447/small/ONT.png?1583481820"}
    , {id: "bittorrent-2", symbol: "btt", image: "https://assets.coingecko.com/coins/images/7595/small/BTT_Token_Graphic.png?1555066995"}
    , {id: "omisego", symbol: "omg", image: "https://assets.coingecko.com/coins/images/776/small/OMG_Network.jpg?1591167168"}
    , {id: "eos", symbol: "eos", image: "https://assets.coingecko.com/coins/images/738/small/eos-eos-logo.png?1547034481"}
    , {id: "neo", symbol: "neo", image: "https://assets.coingecko.com/coins/images/480/small/NEO_512_512.png?1594357361"}
    , {id: "bitcoin-cash", symbol: "bch", image: "https://assets.coingecko.com/coins/images/780/small/bitcoin-cash-circle.png?1594689492"}
    , {id: "chainlink", symbol: "link", image: "https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700"}
    , {id: "stellar", symbol: "xlm", image: "https://assets.coingecko.com/coins/images/100/small/Stellar_symbol_black_RGB.png?1552356157"}
    , {id: "filecoin", symbol: "fil", image: "https://assets.coingecko.com/coins/images/12817/small/filecoin.png?1602753933"}
    , {id: "wink", symbol: "win", image: "https://assets.coingecko.com/coins/images/9129/small/WinK.png?1564624891"}
    , {id: "terra-luna", symbol: "luna", image: "https://assets.coingecko.com/coins/images/8284/small/luna1557227471663.png?1567147072"}
    , {id: "theta-token", symbol: "theta", image: "https://assets.coingecko.com/coins/images/2538/small/theta-token-logo.png?1548387191"}
    , {id: "chiliz", symbol: "chz", image: "https://assets.coingecko.com/coins/images/8834/small/Chiliz.png?1561970540"}
    , {id: "tezos", symbol: "xtz", image: "https://assets.coingecko.com/coins/images/976/small/Tezos-logo.png?1547034862"}
    , {id: "swipe", symbol: "sxp", image: "https://assets.coingecko.com/coins/images/9368/small/swipe.png?1566792311"}
    , {id: "dash", symbol: "dash", image: "https://assets.coingecko.com/coins/images/19/small/dash-logo.png?1548385930"}
    , {id: "uniswap", symbol: "uni", image: "https://assets.coingecko.com/coins/images/12504/small/uniswap-uni.png?1600306604"}
    , {id: "avalanche-2", symbol: "AVAX", image: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818"}
    , {id: "pancakeswap-token", symbol: "cake", image: "https://assets.coingecko.com/coins/images/12632/small/IMG_0440.PNG?1602654093"}
    , {id: "lisk", symbol: "lsk", image: "https://assets.coingecko.com/coins/images/385/small/Lisk_Symbol_-_Blue.png?1573444104"}
    , {id: "monero", symbol: "xmr", image: "https://assets.coingecko.com/coins/images/69/small/monero_logo.png?1547033729"}
    , {id: "zcash", symbol: "zec", image: "https://assets.coingecko.com/coins/images/486/small/circle-zcash-color.png?1547034197"}
    , {id: "solana", symbol: "sol", image: "https://assets.coingecko.com/coins/images/4128/small/coinmarketcap-solana-200.png?1616489452"}
    , {id: "iostoken", symbol: "iost", image: "https://assets.coingecko.com/coins/images/2523/small/IOST.png?1557555183"}
    , {id: "cosmos", symbol: "atom", image: "https://assets.coingecko.com/coins/images/1481/small/cosmos_hub.png?1555657960"}
    , {id: "iota", symbol: "miota", image: "https://assets.coingecko.com/coins/images/692/small/IOTA_Swirl.png?1604238557"}
    , {id: "sushi", symbol: "sushi", image: "https://assets.coingecko.com/coins/images/12271/small/512x512_Logo_no_chop.png?1606986688"}
    , {id: "holotoken", symbol: "hot", image: "https://assets.coingecko.com/coins/images/3348/small/Holologo_Profile.png?1547037966"}
    , {id: "nem", symbol: "xem", image: "https://assets.coingecko.com/coins/images/242/small/NEM_Logo_256x256.png?1598687029"}
    , {id: "true-usd", symbol: "tusd", image: "https://assets.coingecko.com/coins/images/3449/small/tusd.png?1618395665"}
    , {id: "algorand", symbol: "algo", image: "https://assets.coingecko.com/coins/images/4380/small/download.png?1547039725"}
    , {id: "matic-network", symbol: "matic", image: "https://assets.coingecko.com/coins/images/4713/small/matic___polygon.jpg?1612939050"}
    , {id: "zilliqa", symbol: "zil", image: "https://assets.coingecko.com/coins/images/2687/small/Zilliqa-logo.png?1547036894"}
    , {id: "the-graph", symbol: "grt", image: "https://assets.coingecko.com/coins/images/13397/small/Graph_Token.png?1608145566"}
    , {id: "1inch", symbol: "1inch", image: "https://assets.coingecko.com/coins/images/13469/small/1inch-token.png?1608803028"}
    , {id: "curve-dao-token", symbol: "crv", image: "https://assets.coingecko.com/coins/images/12124/small/Curve.png?1597369484"}
    , {id: "ftx-token", symbol: "ftt", image: "https://assets.coingecko.com/coins/images/9026/small/F.png?1609051564"}
]
 
export const businessModal = [
  {
  "id": "1",
  "path_name": "Developer"
  },
  {
  "id": "4",
  "path_name": "Introducers" 
  },
  {
  "id": "2",
  "path_name": "Media & Authors"
  },
  {
  "id": "3",
  "path_name": "Traders"
  }
]


export const countryList =  [
    {
        "country_id": "1",
        "sortname": "AF",
        "country_code": "+93",
        "country_name": "Afghanistan",
        "country_flag": "af.png",
        "currency_code": "AFN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "2",
        "sortname": "AL",
        "country_code": "+355",
        "country_name": "Albania",
        "country_flag": "al.png",
        "currency_code": "ALL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "3",
        "sortname": "DZ",
        "country_code": "+213",
        "country_name": "Algeria",
        "country_flag": "dz.png",
        "currency_code": "DZD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "4",
        "sortname": "AS",
        "country_code": "+1",
        "country_name": "American Samoa",
        "country_flag": "as.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "5",
        "sortname": "AD",
        "country_code": "+376",
        "country_name": "Andorra",
        "country_flag": "ad.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "6",
        "sortname": "AO",
        "country_code": "+244",
        "country_name": "Angola",
        "country_flag": "ao.png",
        "currency_code": "AOA",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "7",
        "sortname": "AI",
        "country_code": "+1",
        "country_name": "Anguilla",
        "country_flag": "al.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "8",
        "sortname": "AQ",
        "country_code": "+672",
        "country_name": "Antarctica",
        "country_flag": "aq.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "9",
        "sortname": "AG",
        "country_code": "+1",
        "country_name": "Antigua And Barbuda",
        "country_flag": "ag.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "10",
        "sortname": "AR",
        "country_code": "+54",
        "country_name": "Argentina",
        "country_flag": "ar.png",
        "currency_code": "ARS",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "11",
        "sortname": "AM",
        "country_code": "+374",
        "country_name": "Armenia",
        "country_flag": "am.png",
        "currency_code": "AMD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "12",
        "sortname": "AW",
        "country_code": "+297",
        "country_name": "Aruba",
        "country_flag": "aw.png",
        "currency_code": "AWG",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "13",
        "sortname": "AU",
        "country_code": "+61",
        "country_name": "Australia",
        "country_flag": "au.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "14",
        "sortname": "AT",
        "country_code": "+43",
        "country_name": "Austria",
        "country_flag": "at.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "15",
        "sortname": "AZ",
        "country_code": "+994",
        "country_name": "Azerbaijan",
        "country_flag": "az.png",
        "currency_code": "AZN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "16",
        "sortname": "BS",
        "country_code": "+1",
        "country_name": "Bahamas The",
        "country_flag": "bs.png",
        "currency_code": "BSD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "17",
        "sortname": "BH",
        "country_code": "+97",
        "country_name": "Bahrain",
        "country_flag": "bh.png",
        "currency_code": "BHD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "18",
        "sortname": "BD",
        "country_code": "+880",
        "country_name": "Bangladesh",
        "country_flag": "bd.png",
        "currency_code": "BDT",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "19",
        "sortname": "BB",
        "country_code": "+1",
        "country_name": "Barbados",
        "country_flag": "bb.png",
        "currency_code": "BBD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "20",
        "sortname": "BY",
        "country_code": "+375",
        "country_name": "Belarus",
        "country_flag": "by.png",
        "currency_code": "BYR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "21",
        "sortname": "BE",
        "country_code": "+32",
        "country_name": "Belgium",
        "country_flag": "be.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "22",
        "sortname": "BZ",
        "country_code": "+501",
        "country_name": "Belize",
        "country_flag": "bz.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "23",
        "sortname": "BJ",
        "country_code": "+229",
        "country_name": "Benin",
        "country_flag": "bj.png",
        "currency_code": "XOF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "24",
        "sortname": "BM",
        "country_code": "+1",
        "country_name": "Bermuda",
        "country_flag": "bm.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "25",
        "sortname": "BT",
        "country_code": "+975",
        "country_name": "Bhutan",
        "country_flag": "bt.png",
        "currency_code": "BTN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "26",
        "sortname": "BO",
        "country_code": "+591",
        "country_name": "Bolivia",
        "country_flag": "bo.png",
        "currency_code": "BOB",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "27",
        "sortname": "BA",
        "country_code": "+387",
        "country_name": "Bosnia and Herzegovina",
        "country_flag": "ba.png",
        "currency_code": "BAM",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "28",
        "sortname": "BW",
        "country_code": "+267",
        "country_name": "Botswana",
        "country_flag": "bw.png",
        "currency_code": "BWP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "29",
        "sortname": "BV",
        "country_code": "+55",
        "country_name": "Bouvet Island",
        "country_flag": "bv.png",
        "currency_code": "NOK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "30",
        "sortname": "BR",
        "country_code": "+55",
        "country_name": "Brazil",
        "country_flag": "br.png",
        "currency_code": "BRL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "31",
        "sortname": "IO",
        "country_code": "+246",
        "country_name": "British Indian Ocean Territory",
        "country_flag": "io.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "32",
        "sortname": "BN",
        "country_code": "+673",
        "country_name": "Brunei",
        "country_flag": "bn.png",
        "currency_code": "BND",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "33",
        "sortname": "BG",
        "country_code": "+359",
        "country_name": "Bulgaria",
        "country_flag": "bg.png",
        "currency_code": "BGN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "34",
        "sortname": "BF",
        "country_code": "+226",
        "country_name": "Burkina Faso",
        "country_flag": "bf.png",
        "currency_code": "XOF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "35",
        "sortname": "BI",
        "country_code": "+257",
        "country_name": "Burundi",
        "country_flag": "bi.png",
        "currency_code": "BIF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "36",
        "sortname": "KH",
        "country_code": "+855",
        "country_name": "Cambodia",
        "country_flag": "kh.png",
        "currency_code": "KHR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "37",
        "sortname": "CM",
        "country_code": "+237",
        "country_name": "Cameroon",
        "country_flag": "cm.png",
        "currency_code": "XAF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "38",
        "sortname": "CA",
        "country_code": "+1",
        "country_name": "Canada",
        "country_flag": "ca.png",
        "currency_code": "CAD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "39",
        "sortname": "CV",
        "country_code": "+238",
        "country_name": "Cape Verde",
        "country_flag": "cv.png",
        "currency_code": "CVE",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "40",
        "sortname": "KY",
        "country_code": "+1",
        "country_name": "Cayman Islands",
        "country_flag": "ky.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "41",
        "sortname": "CF",
        "country_code": "+236",
        "country_name": "Central African Republic",
        "country_flag": "cf.png",
        "currency_code": "XAF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "42",
        "sortname": "TD",
        "country_code": "+235",
        "country_name": "Chad",
        "country_flag": "td.png",
        "currency_code": "XAF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "43",
        "sortname": "CL",
        "country_code": "+56",
        "country_name": "Chile",
        "country_flag": "cl.png",
        "currency_code": "CLP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "44",
        "sortname": "CN",
        "country_code": "+86",
        "country_name": "China",
        "country_flag": "cn.png",
        "currency_code": "CNY",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "45",
        "sortname": "CX",
        "country_code": "+61",
        "country_name": "Christmas Island",
        "country_flag": "cx.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "46",
        "sortname": "CC",
        "country_code": "+891",
        "country_name": "Cocos (Keeling) Islands",
        "country_flag": "cc.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "47",
        "sortname": "CO",
        "country_code": "+57",
        "country_name": "Colombia",
        "country_flag": "co.png",
        "currency_code": "COP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "48",
        "sortname": "KM",
        "country_code": "+269",
        "country_name": "Comoros",
        "country_flag": "km.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "49",
        "sortname": "CG",
        "country_code": "+242",
        "country_name": "Congo",
        "country_flag": "cg.png",
        "currency_code": "XAF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "50",
        "sortname": "CD",
        "country_code": "+242",
        "country_name": "Congo The Democratic Republic Of The",
        "country_flag": "cd.png",
        "currency_code": "CDF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "51",
        "sortname": "CK",
        "country_code": "+682",
        "country_name": "Cook Islands",
        "country_flag": "ck.png",
        "currency_code": "NZD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "52",
        "sortname": "CR",
        "country_code": "+506",
        "country_name": "Costa Rica",
        "country_flag": "cr.png",
        "currency_code": "CRC",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "53",
        "sortname": "CI",
        "country_code": "+225",
        "country_name": "Cote D'Ivoire (Ivory Coast)",
        "country_flag": "cl.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "54",
        "sortname": "HR",
        "country_code": "+385",
        "country_name": "Croatia (Hrvatska)",
        "country_flag": "hr.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "55",
        "sortname": "CU",
        "country_code": "+53",
        "country_name": "Cuba",
        "country_flag": "cu.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "56",
        "sortname": "CY",
        "country_code": "+357",
        "country_name": "Cyprus",
        "country_flag": "cy.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "57",
        "sortname": "CZ",
        "country_code": "+420",
        "country_name": "Czech Republic",
        "country_flag": "cz.png",
        "currency_code": "CZK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "58",
        "sortname": "DK",
        "country_code": "+45",
        "country_name": "Denmark",
        "country_flag": "dk.png",
        "currency_code": "DKK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "59",
        "sortname": "DJ",
        "country_code": "+253",
        "country_name": "Djibouti",
        "country_flag": "dj.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "60",
        "sortname": "DM",
        "country_code": "+1",
        "country_name": "Dominica",
        "country_flag": "dm.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "61",
        "sortname": "DO",
        "country_code": "+1",
        "country_name": "Dominican Republic",
        "country_flag": "do.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "62",
        "sortname": "TP",
        "country_code": "+670",
        "country_name": "East Timor",
        "country_flag": "tp.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "63",
        "sortname": "EC",
        "country_code": "+593",
        "country_name": "Ecuador",
        "country_flag": "ec.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "64",
        "sortname": "EG",
        "country_code": "+20",
        "country_name": "Egypt",
        "country_flag": "eg.png",
        "currency_code": "EGP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "65",
        "sortname": "SV",
        "country_code": "+503",
        "country_name": "El Salvador",
        "country_flag": "sv.png",
        "currency_code": "SVC",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "66",
        "sortname": "GQ",
        "country_code": "+240",
        "country_name": "Equatorial Guinea",
        "country_flag": "gq.png",
        "currency_code": "XAF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "67",
        "sortname": "ER",
        "country_code": "+291",
        "country_name": "Eritrea",
        "country_flag": "er.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "68",
        "sortname": "EE",
        "country_code": "+372",
        "country_name": "Estonia",
        "country_flag": "ee.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "69",
        "sortname": "ET",
        "country_code": "+251",
        "country_name": "Ethiopia",
        "country_flag": "et.png",
        "currency_code": "ETB",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "70",
        "sortname": "XA",
        "country_code": "+672",
        "country_name": "External Territories of Australia",
        "country_flag": "xa.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "71",
        "sortname": "FK",
        "country_code": "+500",
        "country_name": "Falkland Islands",
        "country_flag": "fk.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "72",
        "sortname": "FO",
        "country_code": "+298",
        "country_name": "Faroe Islands",
        "country_flag": "fo.png",
        "currency_code": "DKK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "73",
        "sortname": "FJ",
        "country_code": "+679",
        "country_name": "Fiji Islands",
        "country_flag": "fj.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "74",
        "sortname": "FI",
        "country_code": "+358",
        "country_name": "Finland",
        "country_flag": "fi.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "75",
        "sortname": "FR",
        "country_code": "+33",
        "country_name": "France",
        "country_flag": "fr.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "76",
        "sortname": "GF",
        "country_code": "+594",
        "country_name": "French Guiana",
        "country_flag": "gf.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "77",
        "sortname": "PF",
        "country_code": "+689",
        "country_name": "French Polynesia",
        "country_flag": "pf.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "78",
        "sortname": "TF",
        "country_code": "+262",
        "country_name": "French Southern Territories",
        "country_flag": "tf.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "79",
        "sortname": "GA",
        "country_code": "+24",
        "country_name": "Gabon",
        "country_flag": "ga.png",
        "currency_code": "XAF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "80",
        "sortname": "GM",
        "country_code": "+220",
        "country_name": "Gambia The",
        "country_flag": "gm.png",
        "currency_code": "GMD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "81",
        "sortname": "GE",
        "country_code": "+995",
        "country_name": "Georgia",
        "country_flag": "ge.png",
        "currency_code": "GEL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "82",
        "sortname": "DE",
        "country_code": "+49",
        "country_name": "Germany",
        "country_flag": "de.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "83",
        "sortname": "GH",
        "country_code": "+233",
        "country_name": "Ghana",
        "country_flag": "gh.png",
        "currency_code": "GHS",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "84",
        "sortname": "GI",
        "country_code": "+350",
        "country_name": "Gibraltar",
        "country_flag": "gi.png",
        "currency_code": "GIP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "85",
        "sortname": "GR",
        "country_code": "+30",
        "country_name": "Greece",
        "country_flag": "gr.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "86",
        "sortname": "GL",
        "country_code": "+299",
        "country_name": "Greenland",
        "country_flag": "gl.png",
        "currency_code": "DKK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "87",
        "sortname": "GD",
        "country_code": "+1",
        "country_name": "Grenada",
        "country_flag": "gd.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "88",
        "sortname": "GP",
        "country_code": "+590",
        "country_name": "Guadeloupe",
        "country_flag": "gp.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "89",
        "sortname": "GU",
        "country_code": "+1",
        "country_name": "Guam",
        "country_flag": "gu.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "90",
        "sortname": "GT",
        "country_code": "+502",
        "country_name": "Guatemala",
        "country_flag": "gt.png",
        "currency_code": "QTQ",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "91",
        "sortname": "XU",
        "country_code": "+44-1481",
        "country_name": "Guernsey and Alderney",
        "country_flag": "xu.png",
        "currency_code": "GGP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "92",
        "sortname": "GN",
        "country_code": "+224",
        "country_name": "Guinea",
        "country_flag": "gn.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "93",
        "sortname": "GW",
        "country_code": "+245",
        "country_name": "Guinea-Bissau",
        "country_flag": "gw.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "94",
        "sortname": "GY",
        "country_code": "+592",
        "country_name": "Guyana",
        "country_flag": "gu.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "95",
        "sortname": "HT",
        "country_code": "+509",
        "country_name": "Haiti",
        "country_flag": "ht.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "96",
        "sortname": "HM",
        "country_code": "+0",
        "country_name": "Heard and McDonald Islands",
        "country_flag": "hm.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "97",
        "sortname": "HN",
        "country_code": "+504",
        "country_name": "Honduras",
        "country_flag": "hn.png",
        "currency_code": "HNL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "98",
        "sortname": "HK",
        "country_code": "+852",
        "country_name": "Hong Kong S.A.R.",
        "country_flag": "hk.png",
        "currency_code": "HKD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "99",
        "sortname": "HU",
        "country_code": "+36",
        "country_name": "Hungary",
        "country_flag": "hu.png",
        "currency_code": "HUF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "100",
        "sortname": "IS",
        "country_code": "+354",
        "country_name": "Iceland",
        "country_flag": "is.png",
        "currency_code": "ISK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "101",
        "sortname": "IN",
        "country_code": "+91",
        "country_name": "India",
        "country_flag": "in.png",
        "currency_code": "INR",
        "currency_image": "rupee.svg",
        "currency_min_value": "71.00",
        "currency_max_value": "74.00",
        "active_status": "1"
    },
    {
        "country_id": "102",
        "sortname": "ID",
        "country_code": "+62",
        "country_name": "Indonesia",
        "country_flag": "id.png",
        "currency_code": "IDR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "103",
        "sortname": "IR",
        "country_code": "+98",
        "country_name": "Iran",
        "country_flag": "ir.png",
        "currency_code": "IRR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "104",
        "sortname": "IQ",
        "country_code": "+964",
        "country_name": "Iraq",
        "country_flag": "iq.png",
        "currency_code": "IQD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "105",
        "sortname": "IE",
        "country_code": "+353",
        "country_name": "Ireland",
        "country_flag": "ie.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "106",
        "sortname": "IL",
        "country_code": "+972",
        "country_name": "Israel",
        "country_flag": "il.png",
        "currency_code": "ILS",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "107",
        "sortname": "IT",
        "country_code": "+39",
        "country_name": "Italy",
        "country_flag": "it.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "108",
        "sortname": "JM",
        "country_code": "+1",
        "country_name": "Jamaica",
        "country_flag": "jm.png",
        "currency_code": "JMD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "109",
        "sortname": "JP",
        "country_code": "+81",
        "country_name": "Japan",
        "country_flag": "jp.png",
        "currency_code": "JPY",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "110",
        "sortname": "XJ",
        "country_code": "+44",
        "country_name": "Jersey",
        "country_flag": "xj.png",
        "currency_code": "GBP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "111",
        "sortname": "JO",
        "country_code": "+962",
        "country_name": "Jordan",
        "country_flag": "jo.png",
        "currency_code": "JOD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "112",
        "sortname": "KZ",
        "country_code": "+7",
        "country_name": "Kazakhstan",
        "country_flag": "kz.png",
        "currency_code": "KZT",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "113",
        "sortname": "KE",
        "country_code": "+254",
        "country_name": "Kenya",
        "country_flag": "ke.png",
        "currency_code": "KES",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "114",
        "sortname": "KI",
        "country_code": "+686",
        "country_name": "Kiribati",
        "country_flag": "ki.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "115",
        "sortname": "KP",
        "country_code": "+850",
        "country_name": "Korea North",
        "country_flag": "kp.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "116",
        "sortname": "KR",
        "country_code": "+82",
        "country_name": "Korea South",
        "country_flag": "kr.png",
        "currency_code": "KRW",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "117",
        "sortname": "KW",
        "country_code": "+965",
        "country_name": "Kuwait",
        "country_flag": "kw.png",
        "currency_code": "KWD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "118",
        "sortname": "KG",
        "country_code": "+996",
        "country_name": "Kyrgyzstan",
        "country_flag": "kg.png",
        "currency_code": "KGS",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "119",
        "sortname": "LA",
        "country_code": "+856",
        "country_name": "Laos",
        "country_flag": "la.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "120",
        "sortname": "LV",
        "country_code": "+371",
        "country_name": "Latvia",
        "country_flag": "lv.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "121",
        "sortname": "LB",
        "country_code": "+961",
        "country_name": "Lebanon",
        "country_flag": "lb.png",
        "currency_code": "LBP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "122",
        "sortname": "LS",
        "country_code": "+266",
        "country_name": "Lesotho",
        "country_flag": "ls.png",
        "currency_code": "LSL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "123",
        "sortname": "LR",
        "country_code": "+231",
        "country_name": "Liberia",
        "country_flag": "lr.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "124",
        "sortname": "LY",
        "country_code": "+218",
        "country_name": "Libya",
        "country_flag": "ly.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "125",
        "sortname": "LI",
        "country_code": "+423",
        "country_name": "Liechtenstein",
        "country_flag": "li.png",
        "currency_code": "CHF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "126",
        "sortname": "LT",
        "country_code": "+370",
        "country_name": "Lithuania",
        "country_flag": "lt.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "127",
        "sortname": "LU",
        "country_code": "+352",
        "country_name": "Luxembourg",
        "country_flag": "lu.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "128",
        "sortname": "MO",
        "country_code": "+853",
        "country_name": "Macau S.A.R.",
        "country_flag": "mo.png",
        "currency_code": "MOP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "129",
        "sortname": "MK",
        "country_code": "+389",
        "country_name": "Macedonia",
        "country_flag": "mk.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "130",
        "sortname": "MG",
        "country_code": "+261",
        "country_name": "Madagascar",
        "country_flag": "mg.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "131",
        "sortname": "MW",
        "country_code": "+265",
        "country_name": "Malawi",
        "country_flag": "mw.png",
        "currency_code": "MWK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "132",
        "sortname": "MY",
        "country_code": "+60",
        "country_name": "Malaysia",
        "country_flag": "my.png",
        "currency_code": "MYR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "133",
        "sortname": "MV",
        "country_code": "+960",
        "country_name": "Maldives",
        "country_flag": "mv.png",
        "currency_code": "MVR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "134",
        "sortname": "ML",
        "country_code": "+223",
        "country_name": "Mali",
        "country_flag": "ml.png",
        "currency_code": "XOF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "135",
        "sortname": "MT",
        "country_code": "+356",
        "country_name": "Malta",
        "country_flag": "mt.png",
        "currency_code": "EUR",
        "currency_image": "eur.svg",
        "currency_min_value": "0.88",
        "currency_max_value": "0.92",
        "active_status": "1"
    },
    {
        "country_id": "136",
        "sortname": "XM",
        "country_code": "+44",
        "country_name": "Man (Isle of)",
        "country_flag": "xm.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "137",
        "sortname": "MH",
        "country_code": "+692",
        "country_name": "Marshall Islands",
        "country_flag": "mh.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "138",
        "sortname": "MQ",
        "country_code": "+596",
        "country_name": "Martinique",
        "country_flag": "mq.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "139",
        "sortname": "MR",
        "country_code": "+222",
        "country_name": "Mauritania",
        "country_flag": "mr.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "140",
        "sortname": "MU",
        "country_code": "+230",
        "country_name": "Mauritius",
        "country_flag": "mu.png",
        "currency_code": "MUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "141",
        "sortname": "YT",
        "country_code": "+262",
        "country_name": "Mayotte",
        "country_flag": "yt.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "142",
        "sortname": "MX",
        "country_code": "+52",
        "country_name": "Mexico",
        "country_flag": "mx.png",
        "currency_code": "MXN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "143",
        "sortname": "FM",
        "country_code": "+691",
        "country_name": "Micronesia",
        "country_flag": "fm.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "144",
        "sortname": "MD",
        "country_code": "+373",
        "country_name": "Moldova",
        "country_flag": "md.png",
        "currency_code": "MDL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "145",
        "sortname": "MC",
        "country_code": "+377",
        "country_name": "Monaco",
        "country_flag": "mc.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "146",
        "sortname": "MN",
        "country_code": "+976",
        "country_name": "Mongolia",
        "country_flag": "mn.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "147",
        "sortname": "MS",
        "country_code": "+1",
        "country_name": "Montserrat",
        "country_flag": "ms.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "148",
        "sortname": "MA",
        "country_code": "+212",
        "country_name": "Morocco",
        "country_flag": "ma.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "149",
        "sortname": "MZ",
        "country_code": "+258",
        "country_name": "Mozambique",
        "country_flag": "mz.png",
        "currency_code": "MZN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "150",
        "sortname": "MM",
        "country_code": "+95",
        "country_name": "Myanmar",
        "country_flag": "mm.png",
        "currency_code": "MMK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "151",
        "sortname": "NA",
        "country_code": "+264",
        "country_name": "Namibia",
        "country_flag": "na.png",
        "currency_code": "NAD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "152",
        "sortname": "NR",
        "country_code": "+674",
        "country_name": "Nauru",
        "country_flag": "nr.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "153",
        "sortname": "NP",
        "country_code": "+977",
        "country_name": "Nepal",
        "country_flag": "np.png",
        "currency_code": "NPR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "154",
        "sortname": "AN",
        "country_code": "+599",
        "country_name": "Netherlands Antilles",
        "country_flag": "an.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "155",
        "sortname": "NL",
        "country_code": "+31",
        "country_name": "Netherlands The",
        "country_flag": "nl.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "156",
        "sortname": "NC",
        "country_code": "+687",
        "country_name": "New Caledonia",
        "country_flag": "nc.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "157",
        "sortname": "NZ",
        "country_code": "+64",
        "country_name": "New Zealand",
        "country_flag": "nz.png",
        "currency_code": "NZD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "158",
        "sortname": "NI",
        "country_code": "+505",
        "country_name": "Nicaragua",
        "country_flag": "ni.png",
        "currency_code": "NIO",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "159",
        "sortname": "NE",
        "country_code": "+227",
        "country_name": "Niger",
        "country_flag": "ne.png",
        "currency_code": "XOF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "160",
        "sortname": "NG",
        "country_code": "+234",
        "country_name": "Nigeria",
        "country_flag": "ng.png",
        "currency_code": "NGN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "161",
        "sortname": "NU",
        "country_code": "+683",
        "country_name": "Niue",
        "country_flag": "nu.png",
        "currency_code": "NZD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "162",
        "sortname": "NF",
        "country_code": "+672",
        "country_name": "Norfolk Island",
        "country_flag": "nf.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "163",
        "sortname": "MP",
        "country_code": "+1-670",
        "country_name": "Northern Mariana Islands",
        "country_flag": "mp.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "164",
        "sortname": "NO",
        "country_code": "+47",
        "country_name": "Norway",
        "country_flag": "no.png",
        "currency_code": "NOK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "165",
        "sortname": "OM",
        "country_code": "+968",
        "country_name": "Oman",
        "country_flag": "om.png",
        "currency_code": "OMR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "166",
        "sortname": "PK",
        "country_code": "+92",
        "country_name": "Pakistan",
        "country_flag": "pk.png",
        "currency_code": "PKR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "167",
        "sortname": "PW",
        "country_code": "+680",
        "country_name": "Palau",
        "country_flag": "pw.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "168",
        "sortname": "PS",
        "country_code": "+970",
        "country_name": "Palestinian Territory Occupied",
        "country_flag": "ps.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "169",
        "sortname": "PA",
        "country_code": "+507",
        "country_name": "Panama",
        "country_flag": "pa.png",
        "currency_code": "PAB",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "170",
        "sortname": "PG",
        "country_code": "+675",
        "country_name": "Papua new Guinea",
        "country_flag": "pg.png",
        "currency_code": "PGK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "171",
        "sortname": "PY",
        "country_code": "+595",
        "country_name": "Paraguay",
        "country_flag": "py.png",
        "currency_code": "PYG",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "172",
        "sortname": "PE",
        "country_code": "+51",
        "country_name": "Peru",
        "country_flag": "pe.png",
        "currency_code": "PEN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "173",
        "sortname": "PH",
        "country_code": "+63",
        "country_name": "Philippines",
        "country_flag": "ph.png",
        "currency_code": "PHP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "174",
        "sortname": "PN",
        "country_code": "+64",
        "country_name": "Pitcairn Island",
        "country_flag": "pn.png",
        "currency_code": "NZD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "175",
        "sortname": "PL",
        "country_code": "+48",
        "country_name": "Poland",
        "country_flag": "pl.png",
        "currency_code": "PLN",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "176",
        "sortname": "PT",
        "country_code": "+351",
        "country_name": "Portugal",
        "country_flag": "pt.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "177",
        "sortname": "PR",
        "country_code": "+1",
        "country_name": "Puerto Rico",
        "country_flag": "pr.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "178",
        "sortname": "QA",
        "country_code": "+974",
        "country_name": "Qatar",
        "country_flag": "qa.png",
        "currency_code": "QAR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "179",
        "sortname": "RE",
        "country_code": "+262",
        "country_name": "Reunion",
        "country_flag": "re.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "180",
        "sortname": "RO",
        "country_code": "+40",
        "country_name": "Romania",
        "country_flag": "ro.png",
        "currency_code": "RON",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "181",
        "sortname": "RU",
        "country_code": "+7",
        "country_name": "Russia",
        "country_flag": "ru.png",
        "currency_code": "RUB",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "182",
        "sortname": "RW",
        "country_code": "+250",
        "country_name": "Rwanda",
        "country_flag": "rw.png",
        "currency_code": "RWF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "183",
        "sortname": "SH",
        "country_code": "+290",
        "country_name": "Saint Helena",
        "country_flag": "sh.png",
        "currency_code": "SHP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "184",
        "sortname": "KN",
        "country_code": "+1 869",
        "country_name": "Saint Kitts And Nevis",
        "country_flag": "kn.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "185",
        "sortname": "LC",
        "country_code": "+1 758",
        "country_name": "Saint Lucia",
        "country_flag": "lc.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "186",
        "sortname": "PM",
        "country_code": "+508",
        "country_name": "Saint Pierre and Miquelon",
        "country_flag": "pm.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "187",
        "sortname": "VC",
        "country_code": "+1",
        "country_name": "Saint Vincent And The Grenadines",
        "country_flag": "vc.png",
        "currency_code": "XCD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "188",
        "sortname": "WS",
        "country_code": "+1",
        "country_name": "Samoa",
        "country_flag": "ws.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "189",
        "sortname": "SM",
        "country_code": "+378",
        "country_name": "San Marino",
        "country_flag": "sm.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "190",
        "sortname": "ST",
        "country_code": "+239",
        "country_name": "Sao Tome and Principe",
        "country_flag": "st.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "191",
        "sortname": "SA",
        "country_code": "+966",
        "country_name": "Saudi Arabia",
        "country_flag": "sa.png",
        "currency_code": "SAR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "192",
        "sortname": "SN",
        "country_code": "+221",
        "country_name": "Senegal",
        "country_flag": "sn.png",
        "currency_code": "XOF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "193",
        "sortname": "RS",
        "country_code": "+381",
        "country_name": "Serbia",
        "country_flag": "rs.png",
        "currency_code": "RSD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "194",
        "sortname": "SC",
        "country_code": "+248",
        "country_name": "Seychelles",
        "country_flag": "sc.png",
        "currency_code": "SCR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "195",
        "sortname": "SL",
        "country_code": "+232",
        "country_name": "Sierra Leone",
        "country_flag": "sl.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "196",
        "sortname": "SG",
        "country_code": "+65",
        "country_name": "Singapore",
        "country_flag": "sg.png",
        "currency_code": "SGD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "197",
        "sortname": "SK",
        "country_code": "+421",
        "country_name": "Slovakia",
        "country_flag": "sk.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "198",
        "sortname": "SI",
        "country_code": "+386",
        "country_name": "Slovenia",
        "country_flag": "sl.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "199",
        "sortname": "XG",
        "country_code": "+44",
        "country_name": "Smaller Territories of the UK",
        "country_flag": "xg.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "200",
        "sortname": "SB",
        "country_code": "+677",
        "country_name": "Solomon Islands",
        "country_flag": "sb.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "201",
        "sortname": "SO",
        "country_code": "+252",
        "country_name": "Somalia",
        "country_flag": "so.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "202",
        "sortname": "ZA",
        "country_code": "+27",
        "country_name": "South Africa",
        "country_flag": "za.png",
        "currency_code": "ZAR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "203",
        "sortname": "GS",
        "country_code": "+995",
        "country_name": "South Georgia",
        "country_flag": "gs.png",
        "currency_code": "GBP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "204",
        "sortname": "SS",
        "country_code": "+211",
        "country_name": "South Sudan",
        "country_flag": "ss.png",
        "currency_code": "SSP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "205",
        "sortname": "ES",
        "country_code": "+34",
        "country_name": "Spain",
        "country_flag": "es.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "206",
        "sortname": "LK",
        "country_code": "+94",
        "country_name": "Sri Lanka",
        "country_flag": "lk.png",
        "currency_code": "LKR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "207",
        "sortname": "SD",
        "country_code": "+249",
        "country_name": "Sudan",
        "country_flag": "sd.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "208",
        "sortname": "SR",
        "country_code": "+597",
        "country_name": "Suriname",
        "country_flag": "sr.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "209",
        "sortname": "SJ",
        "country_code": "+47",
        "country_name": "Svalbard And Jan Mayen Islands",
        "country_flag": "sj.png",
        "currency_code": "NOK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "210",
        "sortname": "SZ",
        "country_code": "+268",
        "country_name": "Swaziland",
        "country_flag": "sz.png",
        "currency_code": "SZL",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "211",
        "sortname": "SE",
        "country_code": "+46",
        "country_name": "Sweden",
        "country_flag": "se.png",
        "currency_code": "SEK",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "212",
        "sortname": "CH",
        "country_code": "+268",
        "country_name": "Switzerland",
        "country_flag": "ch.png",
        "currency_code": "CHF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "213",
        "sortname": "SY",
        "country_code": "+46",
        "country_name": "Syria",
        "country_flag": "sy.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "214",
        "sortname": "TW",
        "country_code": "+886",
        "country_name": "Taiwan",
        "country_flag": "tw.png",
        "currency_code": "TWD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "215",
        "sortname": "TJ",
        "country_code": "+992",
        "country_name": "Tajikistan",
        "country_flag": "tj.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "216",
        "sortname": "TZ",
        "country_code": "+255",
        "country_name": "Tanzania",
        "country_flag": "tz.png",
        "currency_code": "TZS",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "217",
        "sortname": "TH",
        "country_code": "+66",
        "country_name": "Thailand",
        "country_flag": "th.png",
        "currency_code": "THB",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "218",
        "sortname": "TG",
        "country_code": "+228",
        "country_name": "Togo",
        "country_flag": "tg.png",
        "currency_code": "XOF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "219",
        "sortname": "TK",
        "country_code": "+690",
        "country_name": "Tokelau",
        "country_flag": "tk.png",
        "currency_code": "NZD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "220",
        "sortname": "TO",
        "country_code": "+676",
        "country_name": "Tonga",
        "country_flag": "to.png",
        "currency_code": "TOP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "221",
        "sortname": "TT",
        "country_code": "+1",
        "country_name": "Trinidad And Tobago",
        "country_flag": "tt.png",
        "currency_code": "TTD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "222",
        "sortname": "TN",
        "country_code": "+216",
        "country_name": "Tunisia",
        "country_flag": "tn.png",
        "currency_code": "TND",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "223",
        "sortname": "TR",
        "country_code": "+90",
        "country_name": "Turkey",
        "country_flag": "tr.png",
        "currency_code": "TRY",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "224",
        "sortname": "TM",
        "country_code": "+993",
        "country_name": "Turkmenistan",
        "country_flag": "tm.png",
        "currency_code": "TMT",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "225",
        "sortname": "TC",
        "country_code": "+1",
        "country_name": "Turks And Caicos Islands",
        "country_flag": "tc.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "226",
        "sortname": "TV",
        "country_code": "+688",
        "country_name": "Tuvalu",
        "country_flag": "tv.png",
        "currency_code": "AUD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "227",
        "sortname": "UG",
        "country_code": "+256",
        "country_name": "Uganda",
        "country_flag": "ug.png",
        "currency_code": "UGX",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "228",
        "sortname": "UA",
        "country_code": "+380",
        "country_name": "Ukraine",
        "country_flag": "ua.png",
        "currency_code": "UAH",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "229",
        "sortname": "AE",
        "country_code": "+971",
        "country_name": "United Arab Emirates",
        "country_flag": "ae.png",
        "currency_code": "AED",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "230",
        "sortname": "GB",
        "country_code": "+44",
        "country_name": "United Kingdom",
        "country_flag": "gb.png",
        "currency_code": "GBP",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "231",
        "sortname": "US",
        "country_code": "+1",
        "country_name": "United States",
        "country_flag": "us.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "232",
        "sortname": "UM",
        "country_code": "+1",
        "country_name": "United States Minor Outlying Islands",
        "country_flag": "um.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "233",
        "sortname": "UY",
        "country_code": "+598",
        "country_name": "Uruguay",
        "country_flag": "uy.png",
        "currency_code": "UYU",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "234",
        "sortname": "UZ",
        "country_code": "+998",
        "country_name": "Uzbekistan",
        "country_flag": "uz.png",
        "currency_code": "UZS",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "235",
        "sortname": "VU",
        "country_code": "+678",
        "country_name": "Vanuatu",
        "country_flag": "vu.png",
        "currency_code": "VUV",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "236",
        "sortname": "VA",
        "country_code": "+39",
        "country_name": "Vatican City State (Holy See)",
        "country_flag": "va.png",
        "currency_code": "EUR",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "237",
        "sortname": "VE",
        "country_code": "+58",
        "country_name": "Venezuela",
        "country_flag": "ve.png",
        "currency_code": "VEF",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "238",
        "sortname": "VN",
        "country_code": "+84",
        "country_name": "Vietnam",
        "country_flag": "vn.png",
        "currency_code": "VND",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "239",
        "sortname": "VG",
        "country_code": "+1",
        "country_name": "Virgin Islands (British)",
        "country_flag": "vg.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "240",
        "sortname": "VI",
        "country_code": "+1",
        "country_name": "Virgin Islands (US)",
        "country_flag": "vi.png",
        "currency_code": "USD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "241",
        "sortname": "WF",
        "country_code": "+681",
        "country_name": "Wallis And Futuna Islands",
        "country_flag": "wf.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "242",
        "sortname": "EH",
        "country_code": "+212",
        "country_name": "Western Sahara",
        "country_flag": "eh.png",
        "currency_code": "MAD",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "243",
        "sortname": "YE",
        "country_code": "+967",
        "country_name": "Yemen",
        "country_flag": "ye.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "244",
        "sortname": "YU",
        "country_code": "+38",
        "country_name": "Yugoslavia",
        "country_flag": "yu.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "245",
        "sortname": "ZM",
        "country_code": "+260",
        "country_name": "Zambia",
        "country_flag": "zm.png",
        "currency_code": "ZMW",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    },
    {
        "country_id": "246",
        "sortname": "ZW",
        "country_code": "+263",
        "country_name": "Zimbabwe",
        "country_flag": "zw.png",
        "currency_code": "",
        "currency_image": "",
        "currency_min_value": "0.00",
        "currency_max_value": "0.00",
        "active_status": "0"
    }
]
