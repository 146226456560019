import React, { useState, useEffect } from 'react';  
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags' 
import ContentLoader from "react-content-loader"; 
import Axios from 'axios'
import moment from 'moment'
import { api_base_url, get_config, cookieDomainExtension } from 'constants/Constants'


export default function List_your_coin()
{   

    const [overview, set_overview] = useState("")
    const [overview_loader_status, set_overview_loader_status] = useState(false)
    const [token_list, set_token_list] = useState([])
    const [token_search_value, set_token_search_value] = useState("")
  

    const [recent_token_list, set_recent_token_list] = useState([])
    const [recent_search_value, set_recent_search_value] = useState("")

    const [recent_token_view_status, set_recent_token_view_status] = useState(false)
    const [top_loosers, set_top_loosers] = useState([])
    const [top_gainers, set_top_gainers] = useState([])
    const [active_tab, set_active_tab] = useState(1)

    useEffect(() => 
    {  
        getOverView()
    }, [])

    useEffect(() => 
    {   
        const getTokens= async () =>
        {
          const resOutput = await Axios.get(`${api_base_url}tokens/front_list?search=${token_search_value}`, get_config(""))
          if(resOutput.data.status)
          {
              console.log(resOutput.data.message)
              set_token_list(resOutput.data.message)
          }
        }

        getTokens()
    }, [token_search_value])

    
    useEffect(() => 
    {  
      const getRecentTokens = async () =>
      { 
        const resOutput = await Axios.get(`${api_base_url}tokens/recent_listed?search=${recent_search_value}`, get_config(""))
        if(resOutput.data.status)
        {
            console.log(resOutput.data.message)
            set_recent_token_list(resOutput.data.message)
            set_recent_token_view_status(true)
        }
      }
      
      getRecentTokens()
    }, [recent_search_value])

   

    const getOverView= async () =>
    {
       const resOutput = await Axios.get(`${api_base_url}tokens/overview`, get_config(""))
       if(resOutput.data.status)
       {  
            set_overview_loader_status(true)
           console.log(resOutput.data.message)
            set_overview(resOutput.data.message)
            set_top_loosers(resOutput.data.message.top_losers)
            set_top_gainers(resOutput.data.message.top_gainers)
       }
    }

    return (
        <> 
      <div id="Content" className="markets_list_page market_page_element">
      <MetaTags>
          <title>Markets - Decentralized Exchange | DEX </title>
          <link rel="canonical" href="https://arrano.network/" />
          <meta name="description" content=" Arrano Network is a powerful and user-friendly decentralized exchange developed by the Arrano Developers community. Arrano DEX..." />
          <meta name="keyword" content="Arrano Network, Arrano DEX, ANO Token, Arrano Tokens, Arrano DeFi, Arrano airdrop , ANO Defi token , Arrano Network Community , Arrano Launchpad, Arrano Exchange Arrano Defi Project , ANO governance token" />
          <meta property="og:title" content="Markets - Decentralized Exchange | DEX" />
          <meta property="og:image" content="/bsc_logo.png" />
        </MetaTags> 
        
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="spread_sheet_two" /> */}
                <h4 className="market_title">Crypto Price by Market cap
                {/* <span className="defi_token_para">Global Cap : $1.20T USD</span><span className="green-td">&#129171;</span> */}
                </h4>
                {/* <p className="defi_token_para">Live market price and token details.</p> */}
              </div>
              
            </div>
      
            <div className="row">
                 <div className="col-lg-6 col-md-12">
                  <div className="user_tokens">
                    <div className="market_exchange ">
                      <h4 className="market-heading" >Top Looser</h4>
                      <div className="markets_top_loser">
                      <table className="table-responsive-class">
                          <tbody>
                          {
                            overview_loader_status ? 
                              top_loosers.length > 0 ?
                              top_loosers.map((e: any, i:any) => 
                                <tr>
                                <td className="sl-no"> <h5>{++i}</h5></td>
                                    <td className="image-token-name">
                                       <h4><Link to={`/market/${e.coin_symbol}`} ><img src={`${api_base_url}uploads/tokens/${e.coin_image}`} alt={e.coin_symbol} className="token_loser_img" /> {e.coin_name} <span className="blue-color">{e.coin_symbol}</span></Link></h4>
                                    </td>
                                    <td className="perentage-change"> <h6 className="red-td text-right"><span><img src="/assets/images/sort-down.png" alt="sort-up" className="dropdown-img"/></span>{e.percentage_change}%</h6></td>
                                </tr>  
                            )
                            :
                            <td colSpan={6} className="text-center"><h5>No Tokens Found!</h5></td>
                            :
                            ""
                          }
                          </tbody>
                      </table>
                      </div>
                    </div>
                    </div> 
                  </div>
                  
                  <div className="col-lg-6 col-md-12">
                    <div className="user_tokens">
                    <div className="market_exchange ">
                    <h4 className="market-heading">Top Gainers</h4>
                      <div className="markets_top_loser">
                      <table className="table-responsive-class">
                          <tbody>
                          { 
                            overview_loader_status ? 
                              top_gainers.length > 0 ?
                              top_gainers.map((e: any, i:any) => 
                                <tr>
                                <td className="sl-no"> <h5>{++i}</h5></td>
                                    <td className="image-token-name">
                                       <h4><Link to={`/market/${e.coin_symbol}`} ><img src={`${api_base_url}uploads/tokens/${e.coin_image}`} alt={e.coin_symbol} className="token_loser_img" />  {e.coin_name} <span className="blue-color">{e.coin_symbol}</span></Link></h4>
                                    </td>
                                    <td className="perentage-change"> <h6 className="green-td text-right"><span><img src="/assets/images/sort-up.png" alt="sort-up" className="dropdown-img"/></span>{e.percentage_change}%</h6></td>
                                </tr>  
                            )
                            :
                            <td colSpan={6} className="text-center"><h5>No Tokens Found!</h5></td>
                            :
                            ""
                          }
                          </tbody>
                      </table>
                      </div>
                    </div>
                    </div> 
                  </div>
            
              </div>

            
            <div className="main_block_market">
              <div className="row">
              <div className="col-md-9">  
                <ul className="nav nav-tabs markets-pg-tabs">
                  <li className="active"  ><a data-toggle="tab" href="#coins" className="active market-heading" onClick={()=>set_active_tab(1)}>Coins </a></li>
                  <li  ><a data-toggle="tab" href="#recentcoins" className="market-heading" onClick={()=>set_active_tab(2)} >Recent Listed</a></li>
                </ul>
                
                    </div>
                    <div className="col-md-3">  
                    <div className="markets-search">
                    <div className="input-group mt-2">
                      {
                        active_tab === 1 ?
                        <>
                        <input type="text" className="form-control" value={token_search_value} placeholder="Search" onChange={(e)=>set_token_search_value(e.target.value)} />
                        </>
                        :
                        <>
                        <input type="text" className="form-control" value={recent_search_value} placeholder="Search" onChange={(e)=>set_recent_search_value(e.target.value)} />
                        </>
                      }
                      
                      <div className="input-group-append">
                        <span className="input-group-text" ><img src="/assets/images/search.png" alt="search"/></span>
                      </div>
                    </div>
                    </div>
                    </div>
                <div className="col-md-12">   
            

                  {/* <h4 className="market-heading">Coins Listed</h4> */}
                  <div className="tab-content">
                  <div className="tab-pane active" id="coins">
                  <div className="row">
                  {/* coin_id: "bitcoin"
                      coin_image: "16520739016278a5ad2293d.png"
                      coin_name: "Bitcoin"
                      coin_symbol: "BTC" */}
                    
                    {
                      token_list.length > 0 ?
                      <>
                      { 
                        token_list.map((item) => 
                           <div className="col-lg-3 col-md-4">
                            {/* eslint-disable-next-line prefer-template */}
                            <Link to={"/market/"+item.coin_symbol.toUpperCase()}>
                              <div className="main_market_display market_list_coins">
                                <h4 style={{textTransform:"capitalize"}}>{item.coin_name} <span style={{textTransform:"uppercase"}}>{item.coin_symbol}</span></h4>
                                <p>Vol: {parseFloat(item.trading_volume).toFixed(2)}</p>  
                                 <img src={`${api_base_url}uploads/tokens/${item.coin_image}`} alt={item.coin_symbol} className="token_listin_img" /> 
                                
                                {
                                  parseFloat(item.price) !== 0 ? 
                                  <>
                                   <h6>$ {parseFloat(item.price).toFixed(2)} 
                                    {
                                      item.percentage_change > 0 ?
                                      <span className="color-green">
                                        (<img src="/assets/images/sort-up.png" alt="sort-up" className="dropdown-img"/> 
                                        {item.percentage_change}%)
                                      </span>
                                      :
                                      item.percentage_change < 0 ?
                                      <span className="color-red">
                                        (<img src="/assets/images/sort-down.png" alt="sort-down" className="dropdown-img"/> 
                                        {item.percentage_change}%)
                                      </span>
                                      :
                                      ""
                                    }
                                    </h6>
                                    <p>Updated {item.updated_date_n_time ? moment(item.updated_date_n_time).fromNow():"--"}</p> 
                                  </>
                                  :
                                  "" 
                                }
                                
                                {/* <h6>Percentage change: {item.percentage_change}%</h6> */}
                                
                              </div>
                            </Link>
                          </div>
                        ) 
                      }
                      </>
                      :
                      <>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="main_market_display">
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                        <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                        <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                      </ContentLoader>
                                    </div>
                                    <div className="col-md-4">
                                      <ContentLoader speed={1}
                                        backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                        <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                        
                                      </ContentLoader>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                            </>
                          }
                      </div>
                    </div>
                    <div className="tab-pane" id="recentcoins">
               
                      <div className="">   
                        <div className="tab-content">
                        <div className="tab-pane active" id="coins">
                        <div className="row">
                      {
                            recent_token_list.length > 0 ?
                            <>
                            { 
                              recent_token_list.map((item) => 
                                <div className="col-lg-3 col-md-4">
                                {/* eslint-disable-next-line prefer-template */}
                                  <Link to={"/market/"+item.coin_symbol.toUpperCase()}>
                                    <div className="main_market_display market_list_coins">
                                      <h4 style={{textTransform:"capitalize"}}>{item.coin_name} <span style={{textTransform:"uppercase"}}>{item.coin_symbol}</span></h4>
                                      <p>Vol: {parseFloat(item.trading_volume).toFixed(2)}</p>  
                                      <img src={`${api_base_url}uploads/tokens/${item.coin_image}`} alt={item.coin_symbol} className="token_listin_img" /> 
                                      {
                                          parseFloat(item.price) !== 0 ? 
                                          <>
                                          <h6>$ {parseFloat(item.price).toFixed(2)} 
                                            {
                                              item.percentage_change > 0 ?
                                              <span className="color-green">
                                                (<img src="/assets/images/sort-up.png" alt="sort-up" className="dropdown-img"/> 
                                                {item.percentage_change}%)
                                              </span>
                                              :
                                              item.percentage_change < 0 ?
                                              <span className="color-red">
                                                (<img src="/assets/images/sort-down.png" alt="sort-down" className="dropdown-img"/> 
                                                {item.percentage_change}%)
                                              </span>
                                              :
                                              ""
                                            }
                                            </h6>
                                            <p>Updated {item.updated_date_n_time ? moment(item.updated_date_n_time).fromNow():"--"}</p> 
                                          </>
                                          :
                                          "" 
                                        }
                                    </div>
                                  </Link>
                                </div>
                              ) 
                            }
                            </>
                            :
                            <>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="main_market_display">
                                  <div className="media">
                                    <div className="media-body">
                                      <div className="row">
                                        <div className="col-md-8">
                                          <ContentLoader speed={1}
                                            backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                            <rect x="0" y="0" rx="0" width="30%" height="10px" />
                                            <rect x="0" y="15" rx="0" width="50%" height="20px" />
                                            <rect x="0" y="40" rx="0" width="60%" height="10px" />
                                          </ContentLoader>
                                        </div>
                                        <div className="col-md-4">
                                          <ContentLoader speed={1}
                                            backgroundColor="#ABB0B2" foregroundColor="#ecebeb"  className="content_rewards" width="100%" height="50px">
                                            <rect x="0" y="0" rx="0" width="100%" height="50px" />
                                            
                                          </ContentLoader>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                </div>
                          
                          </>
                    }  
                        </div>
                        </div>
                        </div>
                        </div>
                      </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}