/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import {Switch, Route} from 'react-router-dom';  
import Create_new from "./create_new"
import Tokens_list from "./index"
import Edit_token from "./edit_token"
import Protected from "../protected"

 export default function Market_Routing()
 {  
    return (  
     <> 
        <Switch>  
            <Route exact path="/manage_tokens"> 
            <Protected cmp={Tokens_list}/>
            </Route>
            <Route path="/manage_tokens/create_new"  > 
            <Protected cmp={Create_new}/>
            </Route>
            <Route path="/manage_tokens/edit_token/:id"  > 
            <Protected cmp={Create_new}/>
            </Route>
        </Switch>  
        {/* <Footer /> */}
        </>
    )  
} 
 
