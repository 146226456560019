/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-curly-brace-presence */ 
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'; 
import Cookies from 'js-cookie';
import Web3 from 'web3'
import UnlockButton from '../ConnectWalletButton';
import {cookieDomainExtension, website_base_url, auth_website_url} from  '../../constants/Constants';


class Topmenu extends Component<any, any> {
  constructor(props: any) 
  {
    super(props)
    let local_wallet_address = "";
    if(Cookies.get('local_wallet_address',{domain:cookieDomainExtension}))
    {
       local_wallet_address = Cookies.get('local_wallet_address',{domain:cookieDomainExtension})
    } 
   
    this.state = { 
        user_name:Cookies.get('local_set_user_name',{domain:cookieDomainExtension}),
        short_wallet_address: local_wallet_address ? this.getShortWalletAddress(local_wallet_address) : null, 
        wallet_address: local_wallet_address, 
        valid_user: false,
        url_path: "",
        connected_address:"",
        connected_call_status:false
    }  
    console.log("auth_website_url", auth_website_url)
    // this.state = {
    //   userToken:Cookies.get('token',{domain:cookieDomainExtension}),
    //   user_name:Cookies.get('local_set_user_name',{domain:cookieDomainExtension}), 
    //   short_wallet_address: local_wallet_address ? getShortWalletAddress(local_wallet_address):"", 
    //   wallet_address: local_wallet_address, 
    //   connected_address:"",
    //   connected_call_status:false,
    //   valid_user: false
    // } 
  
  } 
  
  componentDidMount()
  {  
    // console.log(window.location.pathname);
    this.setLogoutTimeFun();   
    this.checkConnectedAddress()
    if(Cookies.get("token")){ 
      this.setState({valid_user: true})  
    }
  } 

  componentDidUpdate(){
    // eslint-disable-next-line react/destructuring-assignment
    if(window.location.pathname !== this.state.url_path){ 
        this.setState({url_path: window.location.pathname})  
       
     this.setLogoutTimeFun();  
        if(Cookies.get("token")){ 
            this.setState({valid_user: true})  
        }
        else{ 
          this.setState({valid_user: false})  
          Cookies.remove('token');
        } 
    }
  }

  getShortWalletAddress(wallet_address)
  {
    const res1 = wallet_address.substr(0, 4)
    const res2 = wallet_address.substr(wallet_address.length - 4)
    return `${res1}...${res2}`
  }

  
  
  setLogoutTimeFun() 
  { 
    if(Cookies.get('local_user_expire_time'))
    {
      const local_user_expire_time = new Date(Cookies.get("local_user_expire_time") || '{}');
      const local_present_time = new Date();
       
      if(local_present_time > local_user_expire_time)
      {   
        Cookies.set('alert_message', 'Login session expired, login to continue.');
        Cookies.remove('token',{domain:cookieDomainExtension});
        Cookies.remove('local_set_full_name',{domain:cookieDomainExtension});
        Cookies.remove('local_set_email_id',{domain:cookieDomainExtension});
        Cookies.remove('local_email_verify_status',{domain:cookieDomainExtension});
        Cookies.remove('local_user_login_time',{domain:cookieDomainExtension});
        Cookies.remove('local_user_expire_time',{domain:cookieDomainExtension});
        Cookies.remove('local_wallet_address',{domain:cookieDomainExtension});
        Cookies.remove('previous_url',{domain:cookieDomainExtension});
        this.props.history.push('/auth/login');
      } 
    }
  } 

  async checkConnectedAddress()
  {
    if(window.web3 || window.ethereum)
    {
      const web3 = new Web3(window.web3 ? Web3.givenProvider:window.ethereum)
      const network_id = await web3.eth.net.getId()
      console.log("network_id", network_id)
      const checkInArray = [1,56]
      if(checkInArray.includes(network_id))
      { 
        const checkAccount = await web3.eth.getAccounts()

        if(checkAccount)
        {
          if(checkAccount[0] !== undefined)
          {
            this.setState({connected_call_status:true})
            this.setState({connected_address: (checkAccount[0]).toLowerCase()})
            console.log("wallet_address",checkAccount[0])
          }
        }
      }
    }
    else
    {
      this.setState({connected_call_status:true})
    }
  }

  logout()
  {
    Cookies.set('alert_message', 'We hope to see you soon!');
    Cookies.remove('token',{domain:cookieDomainExtension});
    Cookies.remove('local_set_full_name',{domain:cookieDomainExtension});
    Cookies.remove('local_set_email_id',{domain:cookieDomainExtension});
    Cookies.remove('local_email_verify_status',{domain:cookieDomainExtension});
    Cookies.remove('local_user_login_time',{domain:cookieDomainExtension});
    Cookies.remove('local_user_expire_time',{domain:cookieDomainExtension});
    Cookies.remove('local_set_user_name',{domain:cookieDomainExtension});
    Cookies.remove('reverify_sent_email_status',{domain:cookieDomainExtension});
    Cookies.remove('local_wallet_address', {domain:cookieDomainExtension})
    this.props.history.push('/');
    window.location.reload()
  }

render()
{   
  const { user_name, valid_user, wallet_address, connected_address, short_wallet_address, connected_call_status } = this.state
  
  return(
      <div id="Header_wrapper">
        <header id="Header">
          {/* <div id="Action_bar">
            <div className="container">
              <div className="column one">
                <ul className="social">
                  <li key={'a1'}><a href="https://twitter.com/arranonetwork" target="_blank" rel="noreferrer"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
                  <li key={'a2'}><a href="https://t.me/arranonetwork" target="_blank"><i className="fa fa-telegram" aria-hidden="true" /></a></li>
                  <li key={'a3'}><a href="https://medium.com/@Arrano" target="_blank"><i className="fa fa-medium" aria-hidden="true" /></a></li>
                  <li key={'a4'}><a href="https://www.reddit.com/user/ArranoNetwork" target="_blank"><i className="fa fa-reddit-alien" aria-hidden="true" /></a></li>
                </ul> 
              </div>
            </div>
          </div> */}
          <div className="header_placeholder" />
            <div id="Top_bar" style={{backgroundColor: "#222326 !important", position: "initial"}}>
       
              <nav className="navbar navbar-expand-md bg-transparent navbar_top_menu navbar-dark">
                <div className="container">
                <Link className="navbar-brand" to="/"><img src="/assets/images/logo.svg" alt="Logo" /></Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                  <span className="navbar-toggler-icon"></span>
                </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    {
                      user_name ? 
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to="/"><span>Home</span></Link>
                        </li>
                        
                        {/* <li>
                          <a href={`${auth_website_url}swap-andx`}><span>Swap ANDX</span></a>
                        </li> 

                        <li>
                          <a href={`${auth_website_url}user/bounty-program`}><span>Bounty 2.0</span></a>
                        </li>  */}
                        
                        <li >
                          <Link to="/market"><span></span><span>Markets</span></Link>
                        </li>
                        
                        <li>
                        <span className="new_menu_label">New</span>
                          <a href={`${auth_website_url}p2p`}><span></span><span>P2P</span></a>
                        </li>
                        
                        <li className="nav-item dropdown">
                          {
                            short_wallet_address ?
                            <>
                            {
                              wallet_address === connected_address ?
                              <div className="switch_account"><span className="wallet_connect_green"></span> Connected</div>
                              :
                              <div className="switch_account"> <span className="wallet_connect_orange"></span> Not Connected</div>
                            }
                            </>
                            :
                            ""
                          }
                          
                          <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                          {user_name} &nbsp;
                          {
                            short_wallet_address ? 
                            <> 
                              :
                              {short_wallet_address}&nbsp;

                              
                            </>
                            :
                            ""
                          }
                          </a>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" href={`${auth_website_url}user/dashboard`}><span> Dashboard</span></a>
                            <a className="dropdown-item" href={`${auth_website_url}user/profile`}><span> Profile</span></a>
                            <a className="dropdown-item" href={`${auth_website_url}user/connections`}><span> Connections</span></a>
                            <a className="dropdown-item" href={`${auth_website_url}user/ano-funds`}><span> ANO Funds</span></a>
                            <a className="dropdown-item" href={`${auth_website_url}user/rewards`}><span>Rewards</span></a>
                            <a className="dropdown-item" href="https://medium.com/@Arrano" target="_blank"> <span>Blog</span></a>
                            <Link className="dropdown-item" to="/manage_tokens/"><span><span> Manage Token</span></span></Link>
                            
                            <a className="dropdown-item" onClick={()=> this.logout()}><span>Logout</span></a>
                          </div>
                        </li>
                        {/* <li style={{paddingRight: '0'}}>
                          <UnlockButton />
                        </li> */}
                      </ul>
                      :
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                          <Link to="/"><span>Home</span></Link>
                        </li>
                       
                        {/* <li>
                          <a href={`${auth_website_url}swap-andx`}><span>Swap ANDX</span></a>
                        </li>  

                        <li>
                          <a href={`${auth_website_url}user/bounty-program`}><span>Bounty 2.0</span></a>
                        </li>  */}
                        
                        <li >
                          <Link to="/market"><span></span><span>Markets</span></Link>
                        </li>

                        <li>
                        <span className="new_menu_label">New</span>
                          <a href={`${auth_website_url}p2p`}><span></span><span>P2P</span></a>
                        </li>

                        <li className="nav-item">
                          <a href={`${auth_website_url}auth/login`}><span>Login</span></a>
                        </li>
                        <li className="nav-item">
                          <a href={`${auth_website_url}register`} className="reg-account-btn"><span>Create Account</span></a>
                      </li>
                      {/* <li style={{paddingRight: '0'}}>
                        <UnlockButton />
                      </li> */}
                  </ul>
                }
              </div>
              </div>
              </nav> 
              
          </div>
        </header>  
      </div> 
    )
  }
} 
export default withRouter(Topmenu)